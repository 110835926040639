var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.result && _vm.exam)?_c('div',{staticClass:"p-3"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'SATResults', query: { practiceType: _vm.practiceType } }}},[_vm._v(" "+_vm._s(_vm.$t("sat.SAT_Results"))+" ")])],1),(_vm.exam)?_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
              name: 'SATResult',
              params: {
                id: _vm.user_exam_id
              },
              query: { practiceType: _vm.practiceType }
            }}},[_vm._v(" "+_vm._s(_vm.exam.title)+" ")])],1):_vm._e(),(_vm.result && _vm.exam)?_c('el-breadcrumb-item',[_c('kbd',[_vm._v(_vm._s(_vm.questionOrder))])]):_vm._e(),_c('el-breadcrumb-item',[_vm._v(" Explanation ")])],1)],2),_c('Heading',{staticClass:"mb-3",attrs:{"content":"Explanation"}}),(_vm.result && _vm.exam)?_c('div',{staticClass:"question-title"},[_c('el-collapse',{attrs:{"accordion":""},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.practices),function(practice,practiceIndex){return _c('el-collapse-item',{key:practiceIndex,attrs:{"name":practiceIndex + 1}},[_c('template',{slot:"title"},[(_vm.practiceType === 'composePractices')?_c('h6',{staticClass:"m-0"},[_vm._v(" SAT Module "+_vm._s(practiceIndex + 1)+" "),(practice.subject && practice.subject.name)?_c('span',[_vm._v(" : "+_vm._s(_vm.titleCase(practice.subject.name))+" ")]):_vm._e()]):_vm._e(),(
                _vm.practiceType === 'singlePractice' ||
                  _vm.practiceType === 'practice'
              )?_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(practice.exam.title)+" ")]):_vm._e()]),_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"showTags"},[_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question"}),_c('span',{staticStyle:{"line-height":"1.8rem"}},[_vm._v("Correct")])]),_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question wrong"}),_c('span',{staticStyle:{"line-height":"1.8rem"}},[_vm._v("Wrong")])]),_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question empty"}),_c('span',{staticStyle:{"line-height":"1.8rem"}},[_vm._v("Unanswered")])])]),_c('div',_vm._l((practice.exam.exam_questions),function(title,index){return _c('router-link',{key:index,class:{
                  view_question: true,
                  wrong: title.userAnswer
                    ? title.userAnswer.is_correct == 0
                    : false,
                  empty:
                    title.userAnswer === null ||
                    title.userAnswer.answers[0] === null
                },attrs:{"to":{
                  name: 'QuestionExplanation',
                  params: {
                    questionId: title.question_id
                  },
                  query: {
                    ..._vm.$route.query,
                    order: index + 1,
                    passageIndex: practiceIndex + 1,
                    user_exam_answer_id: title.userAnswer
                      ? title.userAnswer.id
                      : null
                  }
                }},on:{"click":function($event){return _vm.viewBrowse(index)}}},[(title.question_id == _vm.questionId)?_c('span',{staticClass:"currentIcon questionsOrderActive"},[_c('i',{staticClass:"fa-solid fa-location-dot"})]):_vm._e(),_vm._v(" "+_vm._s(index + 1)+" ")])}),1)])],2)}),1)],1):_vm._e()],1):_vm._e(),(_vm.isPhone)?_c('div',{staticClass:"test-paper"},[(
        _vm.question &&
          _vm.question.type === 'default' &&
          _vm.question.sat_passage_id > 0 &&
          _vm.question.sat_passage.content
      )?_c('MultipleChoiceWithPassage_M',{attrs:{"mode":"explanation","passageIntro":_vm.question.sat_passage.introduction
          ? _vm.question.sat_passage.introduction.intro
          : _vm.nullF,"passageContent":_vm.getPassage,"questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"showLine":_vm.question.sat_sat_subject.sat_subject.name === 'reading',"activities":_vm.activities}}):(
        _vm.question &&
        (_vm.question.type === 'ap' || _vm.question.type === 'ap_macroeconomic') &&
          (_vm.question.sat_passage === null ||
            _vm.question.sat_passage.content === null)
      )?_c('MultipleChoice_M',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.activities}}):(_vm.question.type === 'math')?_c('Math_M',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.activities}}):_c('MultipleChoice_M',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.activities}})],1):_c('div',{staticClass:"test-paper"},[(
        _vm.question &&
          _vm.question.type === 'default' &&
          _vm.question.sat_passage_id > 0 &&
          _vm.question.sat_passage.content
      )?_c('MultipleChoiceWithPassage',{attrs:{"mode":"explanation","passageIntro":_vm.question.sat_passage.introduction
          ? _vm.question.sat_passage.introduction.intro
          : null,"passageContent":_vm.getPassage,"questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"showLine":_vm.question.sat_sat_subject.sat_subject.name === 'reading',"activities":_vm.answer && _vm.answer.other_status ? _vm.answer.other_status.activities : null}}):(
        _vm.question &&
        (_vm.question.type === 'ap' || _vm.question.type === 'ap_macroeconomic') &&
          (_vm.question.sat_passage === null ||
            _vm.question.sat_passage.content === null)
      )?_c('MultipleChoice',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation ? _vm.question.explanation : '-',"showMark":false,"activities":_vm.answer && _vm.answer.other_status ? _vm.answer.other_status.activities : null}}):(_vm.question && _vm.question.type === 'math')?_c('Math',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.content,"options":_vm.question.sat_options,"answer":_vm.answer !== null ? _vm.answer.answers[0] : '/',"correctAnswer":_vm.question.sat_answers[0].answers[0],"isCorrect":_vm.answer ? _vm.answer.is_correct == 1 : false,"explanation":_vm.question.explanation,"showMark":false,"activities":_vm.answer && _vm.answer.other_status ? _vm.answer.other_status.activities : null}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }